import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'

import { getTierIconClass } from '../../../../utils'
import {
  useFeatureFlags,
  useSnippetContent,
  useTranslation,
} from '../../../../utils/providers'
import {
  AlertBox,
  Icon,
  Image,
  ProductActionButton,
  QtySelect,
  ProductRating,
} from '../../../index'
import ProductPriceHint from '../../PriceInfo/ProductPriceHint'
import ProductPrices from '../../PriceInfo/ProductPrices'

function AddToCartView({
  qty: initQty,
  productData = {},
  ratings,
  hidePopup,
  hasLoadingError,
}) {
  const [qty, setQty] = useState(initQty)
  const { t } = useTranslation()
  const { isFeatureEnabled } = useFeatureFlags()

  const { emptySubscriptions: { description } = {} } =
    useSnippetContent('pdp_config') || {}

  const {
    id,
    small_image,
    images = [],
    manufacturer_name,
    name,
    config_variable_a,
    tiericon,
  } = productData
  const rating = ratings ? ratings[id] : null

  const tierIconClass = getTierIconClass(tiericon)

  const imgUrl = small_image || images[0]

  useEffect(() => {
    window.addEventListener('addtocart:response', hidePopup)
    return () => {
      window.removeEventListener('addtocart:response', hidePopup)
    }
  }, [])

  return (
    <>
      <h1 className="h2 subscription-popup__headline mb-4">
        {t('ABO_PDP_NEW_SUBSCRIPTION')}
      </h1>

      {hasLoadingError && (
        <AlertBox className="d-flex align-items-center mb-3">
          <Icon
            symbol="cf-close-circle-o"
            className="text-inherit font-size-m mr-2"
          />
          {t('REQUEST_ERROR')}
        </AlertBox>
      )}

      <strong className="d-block m-3 font-size-m">{manufacturer_name}</strong>

      <div className="d-flex align-items-center justify-content-center mb-3">
        <Image
          srcFull={imgUrl}
          className="pdp__subscription-popup-image"
          alt=""
        />

        <div className="ml-4">
          <span className="d-block">{name}</span>
          <span className="d-block">{config_variable_a}</span>
          <ProductPrices
            productData={productData}
            classSpecialPrice="font-size-m mb-1"
            classRegularPrice="d-inline-block ml-2 font-size-m"
            classUnitPrice="font-size-xxs d-block my-1"
          />
          <ProductPriceHint className="font-size-xs mb-1" />

          {tierIconClass.map((iconClass, index) => {
            return <i key={index} className={iconClass} />
          })}

          {rating && isFeatureEnabled('product.ratings') && (
            <ProductRating
              productData={productData}
              ratingSummary={rating}
              ratingUrl={false}
              onClick={hidePopup}
            />
          )}
        </div>
      </div>

      {description && <p className="pt-3">{description}</p>}

      <QtySelect
        className="mb-3"
        isDisabled={!productData.availability}
        min={1}
        max={10}
        qty={qty}
        changeQty={setQty}
        type="inputBox"
        productId={id}
      />

      <ProductActionButton
        productData={productData}
        qty={qty}
        disableVariants={true}
        trackingData={{ origin: 'pdp' }}
        className="pdp__subscription-popup-add-to-cart"
      />
    </>
  )
}

AddToCartView.propTypes = {
  qty: PropTypes.number,
  productData: PropTypes.shape({
    id: PropTypes.string,
    small_image: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    manufacturer_name: PropTypes.string,
    name: PropTypes.string,
    config_variable_a: PropTypes.string,
    tiericon: PropTypes.arrayOf(PropTypes.string),
  }),
  ratings: PropTypes.object,
  hidePopup: PropTypes.func,
  hasLoadingError: PropTypes.bool,
}

export default AddToCartView
