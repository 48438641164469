import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import ToastHeader from './ToastHeader'
import ToastBody from './ToastBody'
import ToastFooter from './ToastFooter'
import useToastEvent from './useToastEvent'

/**
 * A generic toast message component that automatically closes after a given
 * timeout. The content can be further enhanced with the {@link ToastHeader},
 * {@link ToastBody} and {@link ToastFooter} components. The visibility of the
 * toast must be controlled from the outside. {@link useToastEvent} serves as
 * convenience hook for that.
 *
 * @param {boolean} isVisible - A visibility flag that indicates if the toast
 *   should be visible
 * @param {function} closeAction - A callback that should set the `isVisible`
 *   prop to `false`
 * @param {any} children - Any content element(s)
 * @param {number} [timeout=5000] - Time in milliseconds after which the
 *   `closeAction` function prop is called. Only has an effect when set to a
 *   value greater than zero.
 * @returns {JSX.Element|null}
 * @constructor
 */
function Toast({ isVisible, closeAction, children, timeout = 5000 }) {
  useEffect(() => {
    let t =
      timeout <= 0
        ? undefined
        : setTimeout(() => {
            if (typeof closeAction === 'function') {
              closeAction()
            }
          }, timeout)

    return () => {
      clearTimeout(t)
    }
  }, [isVisible])

  if (!isVisible) {
    return null
  }
  return <section className="toast-message">{children}</section>
}

Toast.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeAction: PropTypes.func.isRequired,
  children: PropTypes.any,
  timeout: PropTypes.number,
}

export default Toast
export { ToastHeader, ToastBody, ToastFooter, useToastEvent }
