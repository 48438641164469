import React, { useState } from 'react'

import { Button } from '../../../index'
import { useTranslation } from '../../../../utils/providers'

import Toast, {
  ToastBody,
  ToastFooter,
  ToastHeader,
  useToastEvent,
} from '../index'

function AddToSubscriptionToast() {
  const [subscriptionId, setSubscriptionId] = useState(null)
  const [total, setTotal] = useState(null)
  const { t } = useTranslation()

  const { isVisible, closeAction } = useToastEvent(
    'addtosubscription:response',
    ({ detail: { status, subscriptionId: id, finalPrice } }) => {
      if (status === 'ok') {
        setSubscriptionId(id)
        setTotal(finalPrice)
        return true
      }
      return false
    }
  )

  return (
    <Toast isVisible={isVisible} closeAction={closeAction}>
      <ToastHeader icon="cf-check-circle-o" closeAction={closeAction}>
        <strong>{t('PDP_SUBSCRIPTION_TOAST_TITLE')}</strong>
      </ToastHeader>
      {total && (
        <ToastBody>
          <p className="d-flex justify-content-between m-0">
            {t('PDP_SUBSCRIPTION_TOAST_TOTAL')(total)}
          </p>
        </ToastBody>
      )}
      <ToastFooter>
        <Button
          variant="outline-primary"
          className="w-100"
          href={`/cartUpdate/abodelivery/view#id_${subscriptionId}`}
          onClick={closeAction}
        >
          {t('PDP_SUBSCRIPTION_TOAST_BUTTON_GOTO')}
        </Button>
      </ToastFooter>
    </Toast>
  )
}

export default AddToSubscriptionToast
